.all-activities-page {
  height: 100vh;
  .activities-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }

  .title {
    margin-bottom: 5px;
  }

  .number-total-activities {
    font-weight: bold;
    padding-right: 10px;
  }
}
.pagination-control-wrapper {
  justify-content: center !important;
}
.ag-header {
  background-color: #202029 !important;
}
.motif-table-header-renderer {
  color: white !important;
}
.ag-row {
  background-color: #f3f3f3 !important;
  border: none !important;
  border-bottom: 1px solid #d8d8d8 !important;
}
.motif-toggle-switch input:checked + .motif-toggle-switch-slider {
  background-color: white;
}
.motif-toggle-switch input:checked + .motif-toggle-switch-slider::before {
  background-color: #fee600;
}
.ag-row-hover :hover {
  background: none !important;
}

.motif-table .ag-row {
  cursor: pointer;
}
.ag-cell-value {
  display: grid !important;
  text-wrap: wrap !important;
}
.users-list:hover:not(:disabled) {
  background-color: black !important;
  color: white !important;
}
