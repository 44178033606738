.ac {
  display: flex;
  background-color: #2E2E37;
  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    background-color: #FEE600;
    width: 53px;
  }
  &__status {
    display: flex;
    align-items: center;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    width: 131px;
    padding-left: 7.5px;
  }
}