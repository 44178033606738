.small-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
  background-color: white;

  .top-content {
    display: flex;
    justify-content: space-between;

    .icon-box-inverted {
      margin-top: -50px;

      .motif-icon {
        display: flex;
        padding: 20px;
        border-radius: 0.75rem;
        background-color: #373744;
        box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;

        svg {
          color: #fee600;
          width: 30px;
          height: 30px;
        }
      }
    }

    .icon-box {
      margin-top: -50px;

      .motif-icon {
        display: flex;
        padding: 20px;
        border-radius: 0.75rem;
        background-color: #fee600;
        box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .title-box {
    font-size: 18px;
    font-weight: lighter;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 5px;
    .title-wrap {
      // text-wrap: nowrap;
    }
  }

  .total {
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
}