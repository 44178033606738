.category-reports {
  width: 100%;

  .header {
    margin-bottom: 50px;
  }

  .category-table {
    padding: 10px;
  }
  
  .grid-table-charts {
    // display: grid;
    // grid-template-columns: 3fr 1fr;
    // gap: 20px;
    // width: 100%;
    margin-bottom: 25px;
  }

  .grid-small-cards-inverted {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px 0;
  }

  .grid-medium-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 40px 0;
  }

  .vertical-big-bar{
    padding: 10px;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    background-color: white;
    margin-bottom: 50px;
  }

  .grid-table-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 20px 0;
  }

  @media (max-width: 768px) {
    .grid-table-info {
      grid-template-columns: 1fr;
    }
  }

  .middle-content-chart {
    display: flex;
    justify-content: center;
  }

  .grid-small-cards-second-line {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
    gap: 20px;
    padding: 20px 0;
    justify-content: center;
  }

  .bottom-content {
    display: flex;
    justify-content: space-between;
  }

  .medium-box-vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    background-color: white;
  
    .top-content {
      display: flex;
      justify-content: space-between;
  
      .icon-box {
        margin-top: -50px;
  
        .motif-icon {
          display: flex;
          padding: 20px;
          border-radius: 0.75rem;
          background-color: #fee600;
          box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
  
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .middle-content-chart{
      display: flex;
      justify-content: center;
    }
  
    .middle-content.list-gold-members {
      max-height: 250px;
      overflow-y: auto;
    }
    
    .title-box {
      font-size: 18px;
      font-weight: lighter;
    }
  
    .legend-section {
      display: flex;
    }
  }
  .ag-header-cell-comp-wrapper {
    text-wrap: wrap;
  }
}