.medium-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
  background-color: white;

  .top-content {
    display: flex;
    justify-content: space-between;

    .icon-box {
      margin-top: -50px;

      .motif-icon {
        display: flex;
        padding: 20px;
        border-radius: 0.75rem;
        background-color: #fee600;
        box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .middle-content-chart{
    display: flex;
    justify-content: center;
  }

  .middle-content.list-gold-members {
    max-height: 250px;
    overflow-y: auto;
  }
  
  .title-box {
    font-size: 18px;
    font-weight: lighter;
  }

  .legend-section {
    display: flex;
  }
}