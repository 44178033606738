.motif-modal-header-user {
  background-color: #373744;
}

.motif-modal-header {
  min-height: inherit;
}

.motif-modal-header-user > .motif-modal-headline {
  flex-grow: 1;
}

.accordion-title {
  padding: 7px;
}

.user-image {
  width: 150px;
  border-radius: 50%;
  border: 5px solid #fee600;
}
.user-field {
  font-weight: 400;
  margin-left: 10px;
  text-align: left;
}
.volunteer-title {
  color: white !important;
  margin-top: 5px !important;
  text-align: center;
}
.accordion-user-modal {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.user-modal {
  // max-height: 700px !important;
  // max-width: 600px !important;
}
.motif-modal-headline {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.user-modal-button {
  margin: 0;
}

.user-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-field,
.user-info {
  flex: 1;
}

.user-info {
  text-align: right;
  margin-right: 10px;
}

.close-user-modal {
  svg {
    color: white !important;
  }
}

.select-projects {
  min-width: 190px;
}
.success-toast {
  position: absolute;
  top: 0px;
  width: 50%;
}
