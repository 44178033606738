.form-field {
  margin-bottom: 30px;
}
#endDate {
  .react-calendar__month-view__weekdays {
    background: #FEE600;
    color: #282836;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    background: #FEE600;
    color: #282836;
  }
  .motif-calendar-icon {
    fill: #a8990e;
  }
}