.custom-footer {
  display: flex;
  justify-content: flex-end;
  border: 0;
}
.delete-button {
  background-color: red !important;
  color: white !important;
  &:hover:not(:disabled) {
    background-color: black !important;
    color: white;
  }
}
.activity-button-third {
  background-color: #d54729 !important;
  color: white !important;
  border: none !important;
}
