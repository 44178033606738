.image-preview {
}
.close {
  position: relative;
  height: 100%;
  width: 100%;
}
.close-cross {
  right: 0px;
  position: absolute;
  width: auto !important;
  height: auto !important;
}
