.header-wrapper {
  align-items: center;
}

.motif-vertical-navigation-menu .motif-icon {
  color: #fee600 !important;
}
.motif-icon-button {
  height: 100% !important;
}
.motif-dropdown-trigger {
  height: 100% !important;
}
.motif-search-input-icon {
  height: auto !important;
}
.motif-typeahead-close-button {
  height: auto !important;
}
