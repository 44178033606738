.general-reports {
  width: 100%;

  .header {
    margin-bottom: 50px;
  }

  .grid-small-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px 0;
  }

  .grid-medium-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 40px 0;
  }

  .list-gold-members {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 20px;
  }

  .list-members {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .initiative-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .select {
      width: 20%;
    }
  }

  .initiative-total {
    font-size: 18px;
    font-weight: lighter;

    span {
      font-weight: bold;
    }
  }

  .grid-table-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 20px 0;
  }

  @media (max-width: 768px) {
    .grid-table-info {
      grid-template-columns: 1fr;
    }
  }

  .middle-content-chart {
    display: flex;
    justify-content: center;
  }

  .grid-small-cards-second-line {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
    gap: 20px;
    padding: 20px 0;
    justify-content: center;
  }

  .bottom-content {
    display: flex;
    justify-content: space-between;
  }
}

.ag-header-cell-text {
  color: white;
}

.motif-progress-bar-wrapper.motif-progress-bar-wrapper-circle {
  height: 25px !important;
  width: 25px !important;
}

.motif-progress-bar-wrapper
  .motif-progress-bar.motif-progress-bar-circle.motif-progress-bar-indeterminate {
  height: 25px !important;
  width: 25px !important;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reports-table {
  .ag-row {
    cursor: default !important;
  }
}
