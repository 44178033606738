.table-search-component {
  display: flex;
  align-items: center;
  background-color: #373744;
  color: white;
  padding: 5px;
  margin-bottom: 2px;
  .table-search-interactions {
    display: flex;
    width: 100%;
    align-items: center;
    .motif-form-field {
      margin-bottom: 0;
    }
    .motif-form-search {
      flex-grow: 10;
      border-right: 1px solid;
      margin-right: 15px;
      &__input {
        & input {
          background-color: #373744;
          border: none;
          color: white;
          &::placeholder {
            color: white;
          }
        }
        & button svg path {
          fill: white !important;
        }
      }
    }
    .motif-form-switch {
      flex-grow: 1;
    }
  }
}
.anchor-link {
  text-decoration: none;
  &--users {
    text-decoration: none;
    margin-left: 10px;
  }
}
