@use "@ey-xd/motif-react/styles" with (
  $font-path: "~@ey-xd/motif-react/assets/fonts"
);
@use "@ey-xd/motif-core/src/styles/layouts/layout/grid/grid";
@use "@ey-xd/motif-core/src/styles/layouts/layout/container/container";

@use "./reset.scss";
@use "./vars.scss";

.main-container {
  display: flex;
  background-color: #f3f3f3;
  min-height: calc(100vh - 56px);
}

// MOTIF HEADER

.motif-vertical-navigation.motif-vertical-navigation-collapsed {
  height: auto;
}

.motif-vertical-navigation-menuitem:hover {
  background-color: #373744;
}

.motif-vertical-navigation-menuitem-label a {
  text-decoration: none;
  color: white;
}

.routes {
  color: white;
  text-decoration: none;
  z-index: 3;
}
.submenu {
  z-index: 2;
}
.motif-vertical-navigation-submenu-label {
  background-color: #fee600 !important;
  cursor: default !important;
}
.motif-vertical-navigation-submenu-float-title {
  color: black !important;
}
.create-button {
  margin-left: 10px;
}

.motif-form-field-text-input
  .motif-label
  + .motif-input-component
  .motif-input:disabled {
  padding: -1px;
}

.margin-top {
  margin-top: 15px;
}

// MOTIF BODY
.motif-container {
  margin-left: -6px;
}
.loader {
  width: 100px;
  height: 100px;
  border: 5px solid #000000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: fixed;
  top: 35%;
  left: 46%;
}
.icon-ey-giveyrs {
  width: auto;
  height: auto;
  position: fixed;
  top: 35%;
  left: 42%;
  border-radius: 10px;
  background-color: #373744;
  padding: 50px;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
