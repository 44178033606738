.multiple-select {
  .motif-select-input{
    border: 1px solid #d8d8d8 !important;
    background-color: #f3f3f3 !important;
  }

  .dropdown {
    background-color: #373744;
    color: white;
    padding-left: 12px;

    &:hover {
      background-color: #202029;
      color: #fee600;
    }
  }

  .motif-select-checkbox {
    border: 1px solid white;
    &:hover {
      color: #fee600;
    }
  }
}