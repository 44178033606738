.organization-reports {
  width: 100%;

  .header {
    margin-bottom: 50px;
  }

  section {
    margin-top: 50px;
  }

  .motif-h3 {
    margin-bottom: 20px;
  }

  .summary {
    padding: 10px;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    background-color: #fdfdfd;

    .top-content {
      display: flex;
      justify-content: space-between;

      .title-wrap {
        font-size: 18px;
        font-weight: lighter;
        .total{
          font-weight: bold;
        }
      }

      .icon-box-inverted {
        margin-top: -50px;

        .motif-icon {
          display: flex;
          padding: 20px;
          border-radius: 0.75rem;
          background-color: #373744;
          box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;

          svg {
            color: #fee600;
            width: 30px;
            height: 30px;
          }
        }
      }

      .icon-box {
        margin-top: -50px;

        .motif-icon {
          display: flex;
          padding: 20px;
          border-radius: 0.75rem;
          background-color: #fee600;
          box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;

          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .grid-summary {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
      padding: 50px;
    }
  }

  .motif-select {
    width: 644px;
  }

  .motif-card-header {
    justify-content: space-evenly;
    background-color: #2e2e38;
    div {
      display: flex;
      align-items: flex-start;
      margin-top: 5px;
      justify-content: space-between;
      .motif-icon svg path {
        fill: #ffbf1e;
      }
    }
  }

  .motif-card-body {
    .entity-patrono {
      display: flex;
      justify-content: space-evenly;
      div {
        text-align: center;
      }
    }
    .entity-info {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 15px;
      .info-left {
        text-align: center;
        width: 300px;
        margin-right: 20px;
        background-color: #fffbf4;
        border-color: var(--card--border-color);
        border-width: var(--card--border-width);
        border-style: var(--card--border-style);
        border-radius: var(--card--border-radius);
        box-shadow: var(--card--box-shadow);
      }
      .info-right {
        text-align: center;
        width: 300px;
        background-color: #fffbf4;
        border-color: var(--card--border-color);
        border-width: var(--card--border-width);
        border-style: var(--card--border-style);
        border-radius: var(--card--border-radius);
        box-shadow: var(--card--box-shadow);
      }
      p {
        background-color: #2e2e38;
        color: #FFFFFF;
      }
    }
  }

}