.inactive-user {
  background-color: #d9d9d9 !important;
}
.activity-buttons-primary--cancel {
  background-color: transparent !important;
  border-radius: 20px !important;
  &:hover:not(:disabled) {
    background-color: red !important;
  }
}
.delete-icon {
  svg {
    color: red !important;
  }
  .activity-buttons-primary--cancel:hover:not(:disabled) & {
    svg {
      color: white !important;
    }
  }
}
.left-side {
  display: flex;
  align-items: center;
  gap: 15px;

  a {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: inherit;

    .backIcon {
      display: flex;
      align-items: center;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  .title-page {
    border-left: 1px solid #d8d8d8;
    padding-left: 15px;
    display: inline-block;
  }
}
