.all-organizations-page {
  height: 100vh;
  .organizations-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }

  .title {
    margin-bottom: 5px;
  }

  .number-total-organizations {
    font-weight: bold;
    padding-right: 10px;
  }
}
