.gallery-images {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 1fr;
  gap: 15px;
  &-unique {
    width: 216px;
    height: 216px;
    position: relative;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
