.activity-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  &-primary {
    border: none !important;
    margin-left: 10px;
  }
  &-secondary {
    background-color: #f3f3f3;
  }
}
.accordion {
  border: none !important;

  &-title div {
    background-color: #202029 !important;
    color: white !important;
  }
  &-title svg {
    fill: white !important;
  }
  &-title {
    background-color: #202029 !important;
    color: white !important;
  }
  &-box {
    background-color: #f3f3f3;
    padding: 30px 0px !important;
  }
}
.section-page {
  margin-top: 30px;
}

.qr {
  width: 100px;
  height: 100px;
  margin-top: 25px;
}
.activity-buttons-secondary--qr {
  margin-top: 10px;
}
.container-test {
  display: grid;
  grid-template-columns: 55% 45%;
  gap: 30px;
}
.image-test {
  max-width: calc(100% - 30px);
  object-fit: cover;
  width: 100%;
  height: 670px;
}
.activity-buttons-third {
  background-color: #d54729;
  color: white;
  border: none;
  margin-right: 100px;
}
