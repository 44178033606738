.dropdown {
  background-color: #373744;
  color: white;
  padding-left: 12px;
  &:hover {
    background-color: #202029;
    color: #fee600;
  }
}
.input {
  &-label {
    font-weight: 400;
    font-size: 14px;
    &-required {
      color: #f80707;
    }
  }
  &-dropdown {
    border: 1px solid #d8d8d8 !important;
    background-color: #f3f3f3 !important;
    & button {
      background-color: #f3f3f3 !important;
      border: none;
    }
  }
}
