.quick-action-card {
  background-color: white;
  width: 100%;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0;
  }

  .description {
    margin-top: 15px;
    text-align: center;
  }

  img {
    max-height: 323px;
    width: 100%;
    object-fit: cover;
    height: 323px;
  }

  .plus-sign {
    position: relative;
    width: 50px;
    height: 50px;
    background-color: #202029;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .line {
    background-color: #fee600;
    position: absolute;
  }

  .line.horizontal {
    width: 60%;
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .line.vertical {
    width: 1px;
    height: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
