.add-answer-button {
  padding: 10px 20px;
  background-color: #2e2e37;
  color: white;
}
.answers {
  margin-bottom: 20px;
}
.correct-answer {
  margin-top: 10px;
  border: 2px solid green !important;
}
