.input {
  &-box {
    border: 1px solid #d8d8d8 !important;
    background-color: #f3f3f3 !important;
    margin-top: 10px;
  }
  &-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    margin-bottom: 10px;
    &-required {
      color: #f80707;
    }
  }
}
