.projects-reports {
  width: 100%;

  section {
    margin-top: 50px;
  }

  .motif-h3 {
    margin-bottom: 20px;
  }

  .projects-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .select {
      width: 20%;
    }
  }

  .projects-total {
    font-size: 18px;
    font-weight: lighter;

    span {
      font-weight: bold;
    }
  }
}