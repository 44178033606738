@use '../vars.scss';

.wcard {
  padding: 0;
  &__info {
    display: inline-block;
    background-color: #202029; 
    color: white;  
  }
  &__create {
    color: #FEE600;
  }
}