.last-activity {
  min-width: 300px;
  width: 440px;
  .top {
    img {
      height: 234px;
      width: 440px;
      max-width: 440px;
      object-fit: contain;
      background-color: rgba(225, 225, 225, 0.2) !important ;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 15px 0 25px 0;
    .title {
      margin-bottom: 7px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .activity-info {
      ul {
        padding: 8px 28px;
        background-color: #2e2e37;
      }
    
      li {
        display: flex;
        gap: 7px;
      }
    
      p {
        color: white;
        height: 22px;
      }
      .icon {
        display: inline-block;
        color: #fee600;
        height: 16px;
        width: 16px;
      }
    }
  }  

  .description {
    display: flex;
    margin-bottom: 15px;
    margin-left: 28px;
    margin-right: 28px;
    margin-top: 10px;
    color: #2e2e38;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }  
  
  .details-button {
    display: flex;
    align-self: end;
    margin-right: 18px;
    border: 0;
    padding: 15px;
    font-weight: normal;
  }
}
.description ul {
  background-color: initial;
}
