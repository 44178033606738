.last-activities {
  .last-activities-carrousel {
    display: flex;
    .carousel-root {
      width: 100%;
    }
    .carousel.carousel-slider .control-arrow {
      background-color: #2e2e37;
      top: 15px;
    }
  }
}

.carrousel-page {
  display: flex;
  width: 100%;
  margin-top: 15px;
  justify-content: space-evenly;
}
