.new-activity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #D8D8D8;
  padding: 0 0 20px 0;

  .left-side {
    display: flex;
    align-items: center;
    gap: 15px;

    a {
      display: flex;
      align-items: center;
      gap: 10px;
      text-decoration: none;
      color: inherit;

      .backIcon {
        display: flex;
        align-items: center;

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    .title-page {
      border-left: 1px solid #D8D8D8;
      padding-left: 15px;
      display: inline-block;
    }
  }

  .motif-button {
    padding: 10px 20px;
    background-color: #2E2E37;
    color: white;
  }
  .motif-icon{
    height: 32px;
    width: 32px;
  }
}