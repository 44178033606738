.user-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 200ms ease;
  border-radius: 8px;
  &-image {
    height: 160px;
    object-fit: contain;
    background-color: rgba(225, 225, 225, 0.2) !important ;
  }
  &-name {
    background-color: black;
    color: #fee600;
    margin-top: 5px;
  }
  &:hover {
    transform: translate(-10px, -10px);
    cursor: pointer;
    box-shadow: 7px 10px 0 -2px #fee600;
  }
}
